export const state = () => ({
  //判斷是否要重新撈取
  NeedGetData: {
    AccountWallet: true,
    WebSetting: true,
    AreaCode: true,
    AccountSign: true,
    AgentSign: true,
    BankList: true,
    BankCardList: true,
    PaySettingOptions: true,
    PayOtherList: true,
    WithdrawalSettingOptions: true,
    ConversionSettingOptions: true,
    AccountDiscount: true,
    ProductList: true,
    Announcement: true,
    Jackpot: true,
    SlideshowList: true,
    ActivityList: true,
    VirtualChannel: true,
    DiscountActivityTab: true,
    Seo: true,
  },
  Host: "",
  CaptchasImg: "",
  SignCaptchasImg: "",
  AgentSignCaptchasImg: "",
  WebSetting: {},
  Announcement: [],
  UpAgentCode: null,
  UpAgentLineCode: null,
  GoToJoin: false,
  ErrorMsg: '',
  IsMobile: false,
  DeviceType: {},
  UserIp: null,
  hostName: "/",
  fullPath: "",
  IsLeft: true,
  IsDayNight: false,
  CloseAll: [],
  IsAudio: true,
  Jackpot: [],
  GameLoadding: 0,
  FirstAnnouncenent: true,
  Seo: [],
  IsLoading: false,
  BrowserKey: null
});

export const getters = {
  WebSeoName: state => {
    return Object.keys(state.WebSetting.WebSEO).indexOf('title') >= 0 ? state.WebSetting.WebSEO.title : '';
  },
  ShowPageWebSeoName: state => {
    return state.WebSetting.SHOW_PAGE_WEBSEONAME == '1';
  },
  Seo: state => {
    let Data = []
    state.Seo.forEach(element => {
      let item = GetMeunData(element.id, state.IsMobile);
      if (item.url != '')
        Data.push({
          url: item.url,
          ...element
        });
    });
    return Data;
  },
  Meun: state => {
    let Data = [GetMeunData(0, state.IsMobile)]
    if (Object.keys(state.WebSetting).indexOf('Meun') >= 0)
      state.WebSetting.Meun.forEach(element => {
        let item = GetMeunData(element.id, state.IsMobile);
        item.name = element.name || '';
        if (item.url != '')
          Data.push(item);
      });
    return Data
  },
  GameMeun: state => {
    let Data = []
    if (Object.keys(state.WebSetting).indexOf('Meun') >= 0)
      state.WebSetting.Meun.forEach(element => {
        let item = GetMeunData(element.id, state.IsMobile);
        item.name = element.name;
        item.images = item.images;
        item.style = process.env.LayoutsMobileTestStyle;
        if (item.url != '')
          Data.push(item);
      });
    return Data
  },
  MobileMeun: (state, getters) => {
    let Data = []
    if (Object.keys(state.WebSetting).indexOf('Meun') >= 0) {
      state.WebSetting.Meun.forEach(element => {
        let item   = GetMeunData(element.id, state.IsMobile);
        item.name  = element.name;
        item.id    = element.id;
        item.tabs  = getters.Product(element.id);
        if (item.url != '')
          Data.push(item);
      });
    }
    return Data
  },
  Category: state => {
    if (Object.keys(state.WebSetting).indexOf('Meun') < 0)
      return [];
    else
      return state.WebSetting.Meun.map((item, index) => {
        let data = GetMeunData(item.id, state.IsMobile);
        return {
          id: item.id,
          title: data.title
        };
      });
  },
  Wallet: state => (value) => {
    let data = [];
    if (Object.keys(state.WebSetting).indexOf('Wallet') >= 0) {
      state.WebSetting.Wallet.forEach(element => {
        let disabled = false;
        if (element.id == 0)
          disabled = (value == 0) ? true : false;
        else
          disabled = (value && value != 0) ? true : false;

        if (element.seamless === 0)
          data.push({
            value: element.id,
            text: element.name,
            category: element.category_id,
            disabled,
            seamless: element.seamless === 1
          })
      });
    }
    return data
  },
  WalletGroup: state => (Id, Category) => {
    let Data = JSON.parse(JSON.stringify(Category));
    if (Object.keys(state.WebSetting).indexOf('Wallet') >= 0) {
      state.WebSetting.Wallet.forEach(element => {
        if (Object.keys(Data).indexOf(`category_${element.category_id}`) >= 0) {
          let disabled = false;
          if (element.id == 0 && Id == 0)
            disabled = true;
          else if (Id && element.id != 0 && Id != 0)
            disabled = true;

          if (element.seamless === 0)
            Data[`category_${element.category_id}`].options.push({ value: element.id, text: element.name, category: element.category_id, disabled, seamless: element.seamless === 1 });
        }
      });
    }

    return Object.keys(Data).map(key => { return Data[key] }).filter(item => item.options.length > 0);
  },
  Product: (state) => (value) => {
    value = Number(value)
    if (isNaN(value)) return []

    let Data = state.WebSetting.Meun.find(element => element.id === value)
    return Data.products.map((item, index) => {
      return GetProductsData(item, state.IsMobile)
    })
  },
  FooterProductLogo: (state) => {
    let data = [];
    if (Object.keys(state.WebSetting).indexOf('Meun') >= 0) {
      state.WebSetting.Meun.forEach(element => {
        element.products.forEach(e => {
          if (Object.keys(e).indexOf('id') >= 0 && data.indexOf(e.id) < 0 && e.show_footer)
            data.push({ id: e.id, name: e.name })

        });
      });
    }
    return data
  },
  Festival: (state) => (value) => {
    if (Object.keys(state.WebSetting).indexOf(value) < 0)
      return false;
    else
      return state.WebSetting[value];
  },
  HotGame: (state, getters) => {
    if (Object.keys(state.WebSetting).indexOf('HotGame') < 0 && state.WebSetting.HotGame.length == 0)
      return [];

    const Device = state.IsMobile === false ? 1 : 2;
    return state.WebSetting.HotGame.map((Item, index) => {
      Item.tryGameUrl  = `/api/TryGame/${Item.id}?Device=${Device}`;
      Item.playGameUrl = `/api/PlayGame/${Item.id}?Device=${Device}`;
      return Item;
    });
  },
  HotMeun: (state, getters) => {
    let Data = []
    let Products = []
    if (Object.keys(state.WebSetting).indexOf('HotGame') >= 0 && state.WebSetting.HotGame.length >= 0) {
      Data.push({
        id: '0',
        name: null,
        title: "store.category_0",
        category_id: 0,
        content: state.WebSetting.HotGame
      });
    }
    if (Object.keys(state.WebSetting).indexOf('Meun') >= 0) {
      state.WebSetting.Meun.forEach(Item => {
        if (Item.id == '6')
        {
          Data.push({
            id: "999",
            name: null,
            title: `store.category_${Item.id}`,
            category_id: Item.id,
            content: Item.products
          });
        }
        else
        {
          Item.products.forEach(Value => {
            Products.push({
              id: Value.id,
              name: Value.name,
              sort: Value.sort,
              title: null,
              category_id: Item.id,
              content: Item.id == 3 ? null : Value.content
            });
          });
        }
      });      
    }

    Products.sort(function(A, B) {
      return A.sort > B.sort ? 1 : -1;
    }).forEach(Value => {
      Data.push(Value);
    });

    return Data;
  },
};

export const mutations = {
  _setNeedGetData: (state, data) => {
    switch (data.name) {
      case "AccountSign":
        state.NeedGetData.AccountSign = data.type; break;
      case "AreaCode":
        state.NeedGetData.AreaCode = data.type; break;
      case "BankList":
        state.NeedGetData.BankList = data.type; break;
      case "BankCardList":
        state.NeedGetData.BankCardList = data.type; break;
      case "WebSetting":
        state.NeedGetData.WebSetting = data.type; break;
      case "AccountWallet":
        state.NeedGetData.AccountWallet = data.type; break;
      case "Announcement":
        state.NeedGetData.Announcement = data.type; break;
      case "Jackpot":
        state.NeedGetData.Jackpot = data.type; break;
      case "Seo":
        state.NeedGetData.Seo = data.type; break;
    }
  },
  _setHost: (state, data) => { state.Host = data },
  _setCaptchasImg: (state, data) => { state.CaptchasImg = data },
  _setSignCaptchasImg: (state, data) => { state.SignCaptchasImg = data },
  _setAgentSignCaptchasImg: (state, data) => { state.AgentSignCaptchasImg = data },
  _setErrorMsg: (state, data) => { state.ErrorMsg = data },
  _setIsMobile: (state, data) => { state.IsMobile = data },
  _setDeviceType: (state, data) => { state.DeviceType = data },
  _setWebSetting: (state, data) => { state.WebSetting = data },
  _setUserIp: (state, data) => { state.UserIp = data },
  _sethostName: (state, data) => { state.hostName = data },
  _setfullPath: (state, data) => { state.fullPath = data },
  _setGoToJoin: (state, data) => { state.GoToJoin = data },
  _setAnnouncement: (state, data) => { state.Announcement = data },
  _setUpAgentCode: (state, data) => { state.UpAgentCode = data },
  _setUpAgentLineCode: (state, data) => { state.UpAgentLineCode = data },  
  _setIsLeft: (state, data) => { state.IsLeft = data },
  _setIsDayNight: (state, data) => { state.IsDayNight = data },
  _setCloseAll: (state, data) => { state.CloseAll.push(data) },
  _setIsAudio: (state, data) => { state.IsAudio = data },
  _setJackpot: (state, data) => { state.Jackpot = data },
  _setFirstAnnouncenent: (state, data) => { state.FirstAnnouncenent = data },
  _setSeo: (state, data) => { state.Seo = data },
  _setIsLoading: (state, data) => { state.IsLoading = data },
  _setGameLoadding: (state, data) => { state.GameLoadding = data },
  _setBrowserKey: (state, data) => { state.BrowserKey = data },
};

export const actions = {
  _responseStatus({ }, data) {
    return ResponseStatus(this, data);
  },
  _showMsgBox({ }, data) {
    return ShowMsgBox(this, data.title || "", data.msg || "");
  },
  async _changeCaptchas({ commit }) {
    commit("_setCaptchasImg", '/api/Captchas?' + Math.random());
  },
  async _changeSignCaptchas({ commit }) {
    commit("_setSignCaptchasImg", '/api/SignCaptchas?' + Math.random());
  },
  async _changeAgentSignCaptchas({ commit }) {
    commit("_setAgentSignCaptchasImg", '/api/AgentSignCaptchas?' + Math.random());
  },
  async _getJackpot({ state, commit, getters }, host) {
    if (!state.NeedGetData.Jackpot) return

    const response = await this.$axios.get("/api/Jackpot", { host });
    if (response.status || response.status == 'ok')
      commit("_setNeedGetData", { name: 'Jackpot', type: false });
    commit("_setJackpot", response.data);
  },
  async _GetWebSetting({ state, commit, getters }, host) {
    if (!state.NeedGetData.WebSetting)
      return;

    if (host)
      commit("_setHost", host);

    const response = await this.$axios.post("/api/GetWebSetting", { host });
    if (Object.keys(response).indexOf('data') >= 0) {
      commit("_setNeedGetData", { name: 'WebSetting', type: false });
      commit("_setWebSetting", response.data);

      if (Object.keys(response.data).indexOf('UpAgentCode') >= 0 && response.data.UpAgentCode) {
        this.$cookies.remove('UpAgentCode')
        this.$cookies.set('UpAgentCode', response.data.UpAgentCode, { path: '/', maxAge: 60 * 60 })
        commit('_setUpAgentCode', response.data.UpAgentCode);
      }

      getters.Wallet(null).forEach((item, index) => {
        if (Object.keys(state.account.AccountWallet).indexOf(item.value) < 0)
          commit("account/setAccountWalletData", item.value)
      })
    } else {
      console.log(response)
    }
    return;
  },
  async _getAnnouncement({ state, commit, dispatch, getters }, data) {
    if (!state.NeedGetData.Announcement) return
    commit("_setNeedGetData", { name: 'Announcement', type: false });

    const response = await this.$axios.post("/api/Announcement/List");
    if (response.status || response.status == 'ok')
      commit("_setAnnouncement", response.data.list);
  },
  async _getSeo({ state, commit, dispatch, getters }) {
    if (!state.NeedGetData.Seo) return;

    const response = await this.$axios.post('/api/Seo/List');
    return dispatch('_responseStatus', response).then((res) => {
      if (res) {
        commit('_setSeo', response.data.list);
        commit('_setNeedGetData', { name: 'Seo', type: false });
      }
      return res;
    });
  },
  async RefreshToken({ dispatch }, data) {
    const response = await this.$axios.post('/api/refresh', data);
    if (response.status == "ok") {
      this.$auth.setUserToken(response.data.access_token)
      return true
    } else {
      return false
    }
  },
};

var $this = this
function ResponseStatus($this, $data) {
  switch ($data.status) {
    case "error": // 失敗
      if ($data.msg) ShowMsgBox($this, "", $data.msg);
      break;
    case "ok": // 成功
      if ($data.msg) ShowMsgBox($this, "", $data.msg);
      break;
  }

  return $data.status == 'ok'
}

function ShowMsgBox($this, $title, $msg) {
  let AddClass = ''
  if ($msg == 'modal-success') {
    AddClass = 'modal-success'
    $msg = ' ';
  }
  if ($msg == 'modal-warning') {
    AddClass = 'modal-warning'
    $msg = ' ';
  }

  if (!$this || Object.keys($this).indexOf('_vm') < 0) return
  if (!$this._vm) return

  const h = $this._vm.$createElement
  const $msgVNode = h('div', { domProps: { innerHTML: $msg } });
  $this._vm.$bvModal
    .msgBoxOk([$msgVNode], {
      titleHtml: `<div class='circle'><i class='icon icon-note'></i></div><div class='title'><span> ${$title} </span></div>`,
      size: "sm",
      modalClass: `dialog-tips ${AddClass}`,
      titleClass: "title",
      okVariant: "submit",
      centered: true
    });
}

function GetMeunData(id, IsMobile) {
  let MobilePath = (IsMobile ? '/mobile' : '')
  let thisWebName = process.env.WEB_TITLE
  switch (id) {
    case 0:
      return {
        url: `${MobilePath}/activity/activity`,
        class: "icon icon-gift",
        title: "store.activity",
        subtxt: "ACTIVITY",
        blank: false
      }
      break;
    case 1:
      return {
        url: `${MobilePath}/game/sport`,
        class: "icon icon-football",
        gameclass: "img gsport",
        title: "store.sport",
        subtxt: "SPORT",
        blank: false,
        images: "game_gsport"
      }
      break;
    case 2:
      return {
        url: `${MobilePath}/game/live`,
        class: "icon icon-dice",
        gameclass: "img glive",
        title: "store.live",
        subtxt: "LIVE",
        blank: false,
        images: "game_glive"
      }
      break;
    case 3:
      return {
        url: `${MobilePath}/game/egame`,
        class: "icon icon-slot",
        gameclass: "img gegame",
        title: "store.egame",
        subtxt: "eGAME",
        blank: false,
        images: "game_gegame"
      }
      break;
    case 4:
      return {
        url: `${MobilePath}/game/lottery`,
        class: "icon icon-lotto",
        gameclass: "img glotto",
        title: "store.lotto",
        subtxt: "LOTTERY",
        blank: false,
        images: "game_glotto"
      }
      break;
    case 5:
      return {
        url: `${MobilePath}/game/chess`,
        class: "icon icon-poker",
        gameclass: "img gchess",
        title: "store.chess",
        subtxt: "CHESS",
        blank: false,
        images: "game_gchess"
      }
      break;
    case 6:
      return {
        url: `${MobilePath}/game/fish`,
        class: "icon icon-fish",
        gameclass: "img gfish",
        title: "store.fish",
        subtxt: "FISH",
        blank: false,
        images: "game_gfish"
      }
      break;
    case 7:
      return {
        url: `${MobilePath}/game/multiple`,
        class: "icon icon-hundred",
        gameclass: "img gmultiple",
        title: "store.multiple",
        subtxt: "MULTIPLE",
        blank: false,
        images: ""
      }
      break;
    case 8:
      return {
        url: `${MobilePath}/game/battle`,
        class: "icon icon-champion",
        gameclass: "img gbattle",
        title: "store.battle",
        subtxt: "BATTLE",
        blank: false,
        images: ""
      }
      break;
    case 9:
      return {
        url: `${MobilePath}/game/slot`,
        class: "icon icon-slot2",
        gameclass: "img gslot",
        title: "store.slot2",
        subtxt: "SLOT",
        blank: false,
        images: "game_gslot",
        description1: "store.chess_description1"
      }
      break;
    case 11:
      return {
        url: `${MobilePath}/game/animal`,
        class: "icon icon-animal",
        gameclass: "img ganimal",
        title: "store.animal",
        subtxt: "ANIMAL",
        blank: false,
        images: "game_ganimal"
      }
      break;
  }
  return { url: '', class: '', gameclass: '', title: '', subtxt: '' };
}

//soon 敬請期待, new 最新遊戲  game-group else
function GetProductsData(obj, IsMobile) {
  const Device = IsMobile === false ? 1 : 2;

  let Id = obj.id
  let Url = `/api/PlayGame/${obj.game_id}?Device=${Device}`
  let UrlTry = `/api/TryGame/${obj.game_id}?Device=${Device}`
  let TryGame = obj.try_game;
  let Name = obj.description
  let GameName = obj.name
  let WalletCode = obj.wallet_code
  let Maintain = obj.status == '9' ? 'game-uphold' : ''
  let ImgPath = obj.imgPath || null;
  let ProductId = obj.product_id || null;

  return { Url, UrlTry, TryGame, Id, GameName, Name, WalletCode, Maintain, ImgPath, ProductId }
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _646b5d46 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _5a7c794a = () => interopDefault(import('../pages/help/about.vue' /* webpackChunkName: "pages/help/about" */))
const _28e9d39d = () => interopDefault(import('../pages/help/about02.vue' /* webpackChunkName: "pages/help/about02" */))
const _8591325a = () => interopDefault(import('../pages/help/agent.vue' /* webpackChunkName: "pages/help/agent" */))
const _ed2b107a = () => interopDefault(import('../pages/help/appset.vue' /* webpackChunkName: "pages/help/appset" */))
const _3a5d4f56 = () => interopDefault(import('../pages/help/check.vue' /* webpackChunkName: "pages/help/check" */))
const _27c76e86 = () => interopDefault(import('../pages/help/disclaimer.vue' /* webpackChunkName: "pages/help/disclaimer" */))
const _55b8887f = () => interopDefault(import('../pages/help/disclaimer02.vue' /* webpackChunkName: "pages/help/disclaimer02" */))
const _75790b2d = () => interopDefault(import('../pages/help/maintain.vue' /* webpackChunkName: "pages/help/maintain" */))
const _92271b90 = () => interopDefault(import('../pages/help/novice.vue' /* webpackChunkName: "pages/help/novice" */))
const _088627f0 = () => interopDefault(import('../pages/help/question.vue' /* webpackChunkName: "pages/help/question" */))
const _1e0aa11e = () => interopDefault(import('../pages/help/rule.vue' /* webpackChunkName: "pages/help/rule" */))
const _751b66c0 = () => interopDefault(import('../pages/help/rule02.vue' /* webpackChunkName: "pages/help/rule02" */))
const _1d82b2b0 = () => interopDefault(import('../pages/history.vue' /* webpackChunkName: "pages/history" */))
const _5798bada = () => interopDefault(import('../pages/history/hisActivity.vue' /* webpackChunkName: "pages/history/hisActivity" */))
const _4609e616 = () => interopDefault(import('../pages/history/hisBet.vue' /* webpackChunkName: "pages/history/hisBet" */))
const _4a7e3323 = () => interopDefault(import('../pages/history/hisDeposit.vue' /* webpackChunkName: "pages/history/hisDeposit" */))
const _37d26c5d = () => interopDefault(import('../pages/history/hisExchPoint.vue' /* webpackChunkName: "pages/history/hisExchPoint" */))
const _fa6504dc = () => interopDefault(import('../pages/history/hisSale.vue' /* webpackChunkName: "pages/history/hisSale" */))
const _76bfb1b6 = () => interopDefault(import('../pages/history/hisTransfer.vue' /* webpackChunkName: "pages/history/hisTransfer" */))
const _0efead7e = () => interopDefault(import('../pages/mobile/index.vue' /* webpackChunkName: "pages/mobile/index" */))
const _60134492 = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _60347e5e = () => interopDefault(import('../pages/password/chgpw.vue' /* webpackChunkName: "pages/password/chgpw" */))
const _4005b99e = () => interopDefault(import('../pages/password/chgsafepw.vue' /* webpackChunkName: "pages/password/chgsafepw" */))
const _427fa373 = () => interopDefault(import('../pages/share.vue' /* webpackChunkName: "pages/share" */))
const _ccdb1a8e = () => interopDefault(import('../pages/share/hisShare.vue' /* webpackChunkName: "pages/share/hisShare" */))
const _68086043 = () => interopDefault(import('../pages/share/share.vue' /* webpackChunkName: "pages/share/share" */))
const _be0e476a = () => interopDefault(import('../pages/system.vue' /* webpackChunkName: "pages/system" */))
const _079d5fa2 = () => interopDefault(import('../pages/system/info.vue' /* webpackChunkName: "pages/system/info" */))
const _073557aa = () => interopDefault(import('../pages/system/mail.vue' /* webpackChunkName: "pages/system/mail" */))
const _2609ce32 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _4ebbb9e8 = () => interopDefault(import('../pages/user/card.vue' /* webpackChunkName: "pages/user/card" */))
const _480f1544 = () => interopDefault(import('../pages/user/payment.vue' /* webpackChunkName: "pages/user/payment" */))
const _42604381 = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _3f575523 = () => interopDefault(import('../pages/user/user.vue' /* webpackChunkName: "pages/user/user" */))
const _06a5503a = () => interopDefault(import('../pages/user/virtual.vue' /* webpackChunkName: "pages/user/virtual" */))
const _0dbcc915 = () => interopDefault(import('../pages/wallet.vue' /* webpackChunkName: "pages/wallet" */))
const _9895e8b4 = () => interopDefault(import('../pages/wallet/bank.vue' /* webpackChunkName: "pages/wallet/bank" */))
const _afa61fb8 = () => interopDefault(import('../pages/wallet/deposit.vue' /* webpackChunkName: "pages/wallet/deposit" */))
const _6f6db72b = () => interopDefault(import('../pages/wallet/discount.vue' /* webpackChunkName: "pages/wallet/discount" */))
const _4789fd99 = () => interopDefault(import('../pages/wallet/treasure.vue' /* webpackChunkName: "pages/wallet/treasure" */))
const _b33d6660 = () => interopDefault(import('../pages/account/agent_signup.vue' /* webpackChunkName: "pages/account/agent_signup" */))
const _71d68861 = () => interopDefault(import('../pages/account/forgot.vue' /* webpackChunkName: "pages/account/forgot" */))
const _746d6c9b = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _659a13d6 = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _3ca043a3 = () => interopDefault(import('../pages/activity/activity.vue' /* webpackChunkName: "pages/activity/activity" */))
const _243e33ad = () => interopDefault(import('../pages/event/FIFA2022.vue' /* webpackChunkName: "pages/event/FIFA2022" */))
const _04c38f66 = () => interopDefault(import('../pages/game/animal.vue' /* webpackChunkName: "pages/game/animal" */))
const _65c93d09 = () => interopDefault(import('../pages/game/battle.vue' /* webpackChunkName: "pages/game/battle" */))
const _5c0fa8bf = () => interopDefault(import('../pages/game/chess.vue' /* webpackChunkName: "pages/game/chess" */))
const _d3581ffe = () => interopDefault(import('../pages/game/chess02.vue' /* webpackChunkName: "pages/game/chess02" */))
const _3e1c1016 = () => interopDefault(import('../pages/game/egame.vue' /* webpackChunkName: "pages/game/egame" */))
const _fbd7e6c4 = () => interopDefault(import('../pages/game/egame/egame.vue' /* webpackChunkName: "pages/game/egame/egame" */))
const _4d7da62e = () => interopDefault(import('../pages/game/fish.vue' /* webpackChunkName: "pages/game/fish" */))
const _4d7c7d7d = () => interopDefault(import('../pages/game/live.vue' /* webpackChunkName: "pages/game/live" */))
const _28351ec8 = () => interopDefault(import('../pages/game/lottery.vue' /* webpackChunkName: "pages/game/lottery" */))
const _38bec7be = () => interopDefault(import('../pages/game/multiple.vue' /* webpackChunkName: "pages/game/multiple" */))
const _8f8e44e2 = () => interopDefault(import('../pages/game/slot.vue' /* webpackChunkName: "pages/game/slot" */))
const _2290e19a = () => interopDefault(import('../pages/game/sport.vue' /* webpackChunkName: "pages/game/sport" */))
const _2b3f105a = () => interopDefault(import('../pages/mobile/game.vue' /* webpackChunkName: "pages/mobile/game" */))
const _422da668 = () => interopDefault(import('../pages/mobile/game/animal.vue' /* webpackChunkName: "pages/mobile/game/animal" */))
const _ab4ea9b8 = () => interopDefault(import('../pages/mobile/game/battle.vue' /* webpackChunkName: "pages/mobile/game/battle" */))
const _8dc6e978 = () => interopDefault(import('../pages/mobile/game/chess.vue' /* webpackChunkName: "pages/mobile/game/chess" */))
const _c9ae1aca = () => interopDefault(import('../pages/mobile/game/egame.vue' /* webpackChunkName: "pages/mobile/game/egame" */))
const _79093478 = () => interopDefault(import('../pages/mobile/game/fish.vue' /* webpackChunkName: "pages/mobile/game/fish" */))
const _9e610f4c = () => interopDefault(import('../pages/mobile/game/jackpot.vue' /* webpackChunkName: "pages/mobile/game/jackpot" */))
const _37b6b658 = () => interopDefault(import('../pages/mobile/game/live.vue' /* webpackChunkName: "pages/mobile/game/live" */))
const _7593740d = () => interopDefault(import('../pages/mobile/game/lottery.vue' /* webpackChunkName: "pages/mobile/game/lottery" */))
const _420cef7c = () => interopDefault(import('../pages/mobile/game/multiple.vue' /* webpackChunkName: "pages/mobile/game/multiple" */))
const _bb19d32c = () => interopDefault(import('../pages/mobile/game/slot.vue' /* webpackChunkName: "pages/mobile/game/slot" */))
const _4bc471b8 = () => interopDefault(import('../pages/mobile/game/sport.vue' /* webpackChunkName: "pages/mobile/game/sport" */))
const _24c0bba2 = () => interopDefault(import('../pages/mobile/help.vue' /* webpackChunkName: "pages/mobile/help" */))
const _2fcea5e0 = () => interopDefault(import('../pages/mobile/help/about.vue' /* webpackChunkName: "pages/mobile/help/about" */))
const _764828e2 = () => interopDefault(import('../pages/mobile/help/about02.vue' /* webpackChunkName: "pages/mobile/help/about02" */))
const _1a444958 = () => interopDefault(import('../pages/mobile/help/agent.vue' /* webpackChunkName: "pages/mobile/help/agent" */))
const _640c3444 = () => interopDefault(import('../pages/mobile/help/appset.vue' /* webpackChunkName: "pages/mobile/help/appset" */))
const _d12b9c4a = () => interopDefault(import('../pages/mobile/help/check.vue' /* webpackChunkName: "pages/mobile/help/check" */))
const _60c13158 = () => interopDefault(import('../pages/mobile/help/disclaimer.vue' /* webpackChunkName: "pages/mobile/help/disclaimer" */))
const _34c5b65a = () => interopDefault(import('../pages/mobile/help/disclaimer02.vue' /* webpackChunkName: "pages/mobile/help/disclaimer02" */))
const _583542f0 = () => interopDefault(import('../pages/mobile/help/maintain.vue' /* webpackChunkName: "pages/mobile/help/maintain" */))
const _09083f5a = () => interopDefault(import('../pages/mobile/help/novice.vue' /* webpackChunkName: "pages/mobile/help/novice" */))
const _5a293f63 = () => interopDefault(import('../pages/mobile/help/question.vue' /* webpackChunkName: "pages/mobile/help/question" */))
const _0844d9f9 = () => interopDefault(import('../pages/mobile/help/rule.vue' /* webpackChunkName: "pages/mobile/help/rule" */))
const _0a01babb = () => interopDefault(import('../pages/mobile/help/rule02.vue' /* webpackChunkName: "pages/mobile/help/rule02" */))
const _42514b7a = () => interopDefault(import('../pages/mobile/history.vue' /* webpackChunkName: "pages/mobile/history" */))
const _afdc4416 = () => interopDefault(import('../pages/mobile/history/hisActivity.vue' /* webpackChunkName: "pages/mobile/history/hisActivity" */))
const _95b003ca = () => interopDefault(import('../pages/mobile/history/hisBet.vue' /* webpackChunkName: "pages/mobile/history/hisBet" */))
const _4d16c0a8 = () => interopDefault(import('../pages/mobile/history/hisDeposit.vue' /* webpackChunkName: "pages/mobile/history/hisDeposit" */))
const _1304a6bc = () => interopDefault(import('../pages/mobile/history/hisExchPoint.vue' /* webpackChunkName: "pages/mobile/history/hisExchPoint" */))
const _111b33a6 = () => interopDefault(import('../pages/mobile/history/hisSale.vue' /* webpackChunkName: "pages/mobile/history/hisSale" */))
const _718e565e = () => interopDefault(import('../pages/mobile/history/hisTransfer.vue' /* webpackChunkName: "pages/mobile/history/hisTransfer" */))
const _d517c508 = () => interopDefault(import('../pages/mobile/password.vue' /* webpackChunkName: "pages/mobile/password" */))
const _3f03d8d6 = () => interopDefault(import('../pages/mobile/password/chgpw.vue' /* webpackChunkName: "pages/mobile/password/chgpw" */))
const _429e4723 = () => interopDefault(import('../pages/mobile/password/chgsafepw.vue' /* webpackChunkName: "pages/mobile/password/chgsafepw" */))
const _6b52fc64 = () => interopDefault(import('../pages/mobile/share.vue' /* webpackChunkName: "pages/mobile/share" */))
const _08b08abe = () => interopDefault(import('../pages/mobile/share/hisShare.vue' /* webpackChunkName: "pages/mobile/share/hisShare" */))
const _a6d06344 = () => interopDefault(import('../pages/mobile/share/share.vue' /* webpackChunkName: "pages/mobile/share/share" */))
const _13fdc950 = () => interopDefault(import('../pages/mobile/system.vue' /* webpackChunkName: "pages/mobile/system" */))
const _4c2ccdbd = () => interopDefault(import('../pages/mobile/system/info.vue' /* webpackChunkName: "pages/mobile/system/info" */))
const _40f4c246 = () => interopDefault(import('../pages/mobile/system/mail.vue' /* webpackChunkName: "pages/mobile/system/mail" */))
const _43f1832c = () => interopDefault(import('../pages/mobile/user.vue' /* webpackChunkName: "pages/mobile/user" */))
const _38f5f2c3 = () => interopDefault(import('../pages/mobile/user/card.vue' /* webpackChunkName: "pages/mobile/user/card" */))
const _2956caa3 = () => interopDefault(import('../pages/mobile/user/payment.vue' /* webpackChunkName: "pages/mobile/user/payment" */))
const _e082ce74 = () => interopDefault(import('../pages/mobile/user/profile.vue' /* webpackChunkName: "pages/mobile/user/profile" */))
const _29918dfe = () => interopDefault(import('../pages/mobile/user/user.vue' /* webpackChunkName: "pages/mobile/user/user" */))
const _4a0bad28 = () => interopDefault(import('../pages/mobile/user/virtual.vue' /* webpackChunkName: "pages/mobile/user/virtual" */))
const _fe7c93cc = () => interopDefault(import('../pages/mobile/wallet.vue' /* webpackChunkName: "pages/mobile/wallet" */))
const _0f770c7e = () => interopDefault(import('../pages/mobile/wallet/bank.vue' /* webpackChunkName: "pages/mobile/wallet/bank" */))
const _174b0829 = () => interopDefault(import('../pages/mobile/wallet/deposit.vue' /* webpackChunkName: "pages/mobile/wallet/deposit" */))
const _37dac074 = () => interopDefault(import('../pages/mobile/wallet/discount.vue' /* webpackChunkName: "pages/mobile/wallet/discount" */))
const _5e312f1e = () => interopDefault(import('../pages/mobile/wallet/hisDeposit.vue' /* webpackChunkName: "pages/mobile/wallet/hisDeposit" */))
const _2f6a09f8 = () => interopDefault(import('../pages/mobile/wallet/hisSale.vue' /* webpackChunkName: "pages/mobile/wallet/hisSale" */))
const _87a23398 = () => interopDefault(import('../pages/mobile/wallet/treasure.vue' /* webpackChunkName: "pages/mobile/wallet/treasure" */))
const _650c8d15 = () => interopDefault(import('../pages/mobile/account/agent_signup.vue' /* webpackChunkName: "pages/mobile/account/agent_signup" */))
const _3e16bf34 = () => interopDefault(import('../pages/mobile/account/forgot.vue' /* webpackChunkName: "pages/mobile/account/forgot" */))
const _5a4c8014 = () => interopDefault(import('../pages/mobile/account/login.vue' /* webpackChunkName: "pages/mobile/account/login" */))
const _568fa84a = () => interopDefault(import('../pages/mobile/account/signup.vue' /* webpackChunkName: "pages/mobile/account/signup" */))
const _1bad717e = () => interopDefault(import('../pages/mobile/activity/activity.vue' /* webpackChunkName: "pages/mobile/activity/activity" */))
const _1cf777e6 = () => interopDefault(import('../pages/mobile/activity/activity_old.vue' /* webpackChunkName: "pages/mobile/activity/activity_old" */))
const _d947689c = () => interopDefault(import('../pages/mobile/event/FIFA2022.vue' /* webpackChunkName: "pages/mobile/event/FIFA2022" */))
const _3de885b5 = () => interopDefault(import('../pages/a/_code/index.vue' /* webpackChunkName: "pages/a/_code/index" */))
const _70a9cae6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/help",
    component: _646b5d46,
    name: "help",
    children: [{
      path: "about",
      component: _5a7c794a,
      name: "help-about"
    }, {
      path: "about02",
      component: _28e9d39d,
      name: "help-about02"
    }, {
      path: "agent",
      component: _8591325a,
      name: "help-agent"
    }, {
      path: "appset",
      component: _ed2b107a,
      name: "help-appset"
    }, {
      path: "check",
      component: _3a5d4f56,
      name: "help-check"
    }, {
      path: "disclaimer",
      component: _27c76e86,
      name: "help-disclaimer"
    }, {
      path: "disclaimer02",
      component: _55b8887f,
      name: "help-disclaimer02"
    }, {
      path: "maintain",
      component: _75790b2d,
      name: "help-maintain"
    }, {
      path: "novice",
      component: _92271b90,
      name: "help-novice"
    }, {
      path: "question",
      component: _088627f0,
      name: "help-question"
    }, {
      path: "rule",
      component: _1e0aa11e,
      name: "help-rule"
    }, {
      path: "rule02",
      component: _751b66c0,
      name: "help-rule02"
    }]
  }, {
    path: "/history",
    component: _1d82b2b0,
    name: "history",
    children: [{
      path: "hisActivity",
      component: _5798bada,
      name: "history-hisActivity"
    }, {
      path: "hisBet",
      component: _4609e616,
      name: "history-hisBet"
    }, {
      path: "hisDeposit",
      component: _4a7e3323,
      name: "history-hisDeposit"
    }, {
      path: "hisExchPoint",
      component: _37d26c5d,
      name: "history-hisExchPoint"
    }, {
      path: "hisSale",
      component: _fa6504dc,
      name: "history-hisSale"
    }, {
      path: "hisTransfer",
      component: _76bfb1b6,
      name: "history-hisTransfer"
    }]
  }, {
    path: "/mobile",
    component: _0efead7e,
    name: "mobile"
  }, {
    path: "/password",
    component: _60134492,
    name: "password",
    children: [{
      path: "chgpw",
      component: _60347e5e,
      name: "password-chgpw"
    }, {
      path: "chgsafepw",
      component: _4005b99e,
      name: "password-chgsafepw"
    }]
  }, {
    path: "/share",
    component: _427fa373,
    name: "share",
    children: [{
      path: "hisShare",
      component: _ccdb1a8e,
      name: "share-hisShare"
    }, {
      path: "share",
      component: _68086043,
      name: "share-share"
    }]
  }, {
    path: "/system",
    component: _be0e476a,
    name: "system",
    children: [{
      path: "info",
      component: _079d5fa2,
      name: "system-info"
    }, {
      path: "mail",
      component: _073557aa,
      name: "system-mail"
    }]
  }, {
    path: "/user",
    component: _2609ce32,
    name: "user",
    children: [{
      path: "card",
      component: _4ebbb9e8,
      name: "user-card"
    }, {
      path: "payment",
      component: _480f1544,
      name: "user-payment"
    }, {
      path: "profile",
      component: _42604381,
      name: "user-profile"
    }, {
      path: "user",
      component: _3f575523,
      name: "user-user"
    }, {
      path: "virtual",
      component: _06a5503a,
      name: "user-virtual"
    }]
  }, {
    path: "/wallet",
    component: _0dbcc915,
    name: "wallet",
    children: [{
      path: "bank",
      component: _9895e8b4,
      name: "wallet-bank"
    }, {
      path: "deposit",
      component: _afa61fb8,
      name: "wallet-deposit"
    }, {
      path: "discount",
      component: _6f6db72b,
      name: "wallet-discount"
    }, {
      path: "treasure",
      component: _4789fd99,
      name: "wallet-treasure"
    }]
  }, {
    path: "/account/agent_signup",
    component: _b33d6660,
    name: "account-agent_signup"
  }, {
    path: "/account/forgot",
    component: _71d68861,
    name: "account-forgot"
  }, {
    path: "/account/login",
    component: _746d6c9b,
    name: "account-login"
  }, {
    path: "/account/signup",
    component: _659a13d6,
    name: "account-signup"
  }, {
    path: "/activity/activity",
    component: _3ca043a3,
    name: "activity-activity"
  }, {
    path: "/event/FIFA2022",
    component: _243e33ad,
    name: "event-FIFA2022"
  }, {
    path: "/game/animal",
    component: _04c38f66,
    name: "game-animal"
  }, {
    path: "/game/battle",
    component: _65c93d09,
    name: "game-battle"
  }, {
    path: "/game/chess",
    component: _5c0fa8bf,
    name: "game-chess"
  }, {
    path: "/game/chess02",
    component: _d3581ffe,
    name: "game-chess02"
  }, {
    path: "/game/egame",
    component: _3e1c1016,
    name: "game-egame",
    children: [{
      path: "egame",
      component: _fbd7e6c4,
      name: "game-egame-egame"
    }]
  }, {
    path: "/game/fish",
    component: _4d7da62e,
    name: "game-fish"
  }, {
    path: "/game/live",
    component: _4d7c7d7d,
    name: "game-live"
  }, {
    path: "/game/lottery",
    component: _28351ec8,
    name: "game-lottery"
  }, {
    path: "/game/multiple",
    component: _38bec7be,
    name: "game-multiple"
  }, {
    path: "/game/slot",
    component: _8f8e44e2,
    name: "game-slot"
  }, {
    path: "/game/sport",
    component: _2290e19a,
    name: "game-sport"
  }, {
    path: "/mobile/game",
    component: _2b3f105a,
    name: "mobile-game",
    children: [{
      path: "animal",
      component: _422da668,
      name: "mobile-game-animal"
    }, {
      path: "battle",
      component: _ab4ea9b8,
      name: "mobile-game-battle"
    }, {
      path: "chess",
      component: _8dc6e978,
      name: "mobile-game-chess"
    }, {
      path: "egame",
      component: _c9ae1aca,
      name: "mobile-game-egame"
    }, {
      path: "fish",
      component: _79093478,
      name: "mobile-game-fish"
    }, {
      path: "jackpot",
      component: _9e610f4c,
      name: "mobile-game-jackpot"
    }, {
      path: "live",
      component: _37b6b658,
      name: "mobile-game-live"
    }, {
      path: "lottery",
      component: _7593740d,
      name: "mobile-game-lottery"
    }, {
      path: "multiple",
      component: _420cef7c,
      name: "mobile-game-multiple"
    }, {
      path: "slot",
      component: _bb19d32c,
      name: "mobile-game-slot"
    }, {
      path: "sport",
      component: _4bc471b8,
      name: "mobile-game-sport"
    }]
  }, {
    path: "/mobile/help",
    component: _24c0bba2,
    name: "mobile-help",
    children: [{
      path: "about",
      component: _2fcea5e0,
      name: "mobile-help-about"
    }, {
      path: "about02",
      component: _764828e2,
      name: "mobile-help-about02"
    }, {
      path: "agent",
      component: _1a444958,
      name: "mobile-help-agent"
    }, {
      path: "appset",
      component: _640c3444,
      name: "mobile-help-appset"
    }, {
      path: "check",
      component: _d12b9c4a,
      name: "mobile-help-check"
    }, {
      path: "disclaimer",
      component: _60c13158,
      name: "mobile-help-disclaimer"
    }, {
      path: "disclaimer02",
      component: _34c5b65a,
      name: "mobile-help-disclaimer02"
    }, {
      path: "maintain",
      component: _583542f0,
      name: "mobile-help-maintain"
    }, {
      path: "novice",
      component: _09083f5a,
      name: "mobile-help-novice"
    }, {
      path: "question",
      component: _5a293f63,
      name: "mobile-help-question"
    }, {
      path: "rule",
      component: _0844d9f9,
      name: "mobile-help-rule"
    }, {
      path: "rule02",
      component: _0a01babb,
      name: "mobile-help-rule02"
    }]
  }, {
    path: "/mobile/history",
    component: _42514b7a,
    name: "mobile-history",
    children: [{
      path: "hisActivity",
      component: _afdc4416,
      name: "mobile-history-hisActivity"
    }, {
      path: "hisBet",
      component: _95b003ca,
      name: "mobile-history-hisBet"
    }, {
      path: "hisDeposit",
      component: _4d16c0a8,
      name: "mobile-history-hisDeposit"
    }, {
      path: "hisExchPoint",
      component: _1304a6bc,
      name: "mobile-history-hisExchPoint"
    }, {
      path: "hisSale",
      component: _111b33a6,
      name: "mobile-history-hisSale"
    }, {
      path: "hisTransfer",
      component: _718e565e,
      name: "mobile-history-hisTransfer"
    }]
  }, {
    path: "/mobile/password",
    component: _d517c508,
    name: "mobile-password",
    children: [{
      path: "chgpw",
      component: _3f03d8d6,
      name: "mobile-password-chgpw"
    }, {
      path: "chgsafepw",
      component: _429e4723,
      name: "mobile-password-chgsafepw"
    }]
  }, {
    path: "/mobile/share",
    component: _6b52fc64,
    name: "mobile-share",
    children: [{
      path: "hisShare",
      component: _08b08abe,
      name: "mobile-share-hisShare"
    }, {
      path: "share",
      component: _a6d06344,
      name: "mobile-share-share"
    }]
  }, {
    path: "/mobile/system",
    component: _13fdc950,
    name: "mobile-system",
    children: [{
      path: "info",
      component: _4c2ccdbd,
      name: "mobile-system-info"
    }, {
      path: "mail",
      component: _40f4c246,
      name: "mobile-system-mail"
    }]
  }, {
    path: "/mobile/user",
    component: _43f1832c,
    name: "mobile-user",
    children: [{
      path: "card",
      component: _38f5f2c3,
      name: "mobile-user-card"
    }, {
      path: "payment",
      component: _2956caa3,
      name: "mobile-user-payment"
    }, {
      path: "profile",
      component: _e082ce74,
      name: "mobile-user-profile"
    }, {
      path: "user",
      component: _29918dfe,
      name: "mobile-user-user"
    }, {
      path: "virtual",
      component: _4a0bad28,
      name: "mobile-user-virtual"
    }]
  }, {
    path: "/mobile/wallet",
    component: _fe7c93cc,
    name: "mobile-wallet",
    children: [{
      path: "bank",
      component: _0f770c7e,
      name: "mobile-wallet-bank"
    }, {
      path: "deposit",
      component: _174b0829,
      name: "mobile-wallet-deposit"
    }, {
      path: "discount",
      component: _37dac074,
      name: "mobile-wallet-discount"
    }, {
      path: "hisDeposit",
      component: _5e312f1e,
      name: "mobile-wallet-hisDeposit"
    }, {
      path: "hisSale",
      component: _2f6a09f8,
      name: "mobile-wallet-hisSale"
    }, {
      path: "treasure",
      component: _87a23398,
      name: "mobile-wallet-treasure"
    }]
  }, {
    path: "/mobile/account/agent_signup",
    component: _650c8d15,
    name: "mobile-account-agent_signup"
  }, {
    path: "/mobile/account/forgot",
    component: _3e16bf34,
    name: "mobile-account-forgot"
  }, {
    path: "/mobile/account/login",
    component: _5a4c8014,
    name: "mobile-account-login"
  }, {
    path: "/mobile/account/signup",
    component: _568fa84a,
    name: "mobile-account-signup"
  }, {
    path: "/mobile/activity/activity",
    component: _1bad717e,
    name: "mobile-activity-activity"
  }, {
    path: "/mobile/activity/activity_old",
    component: _1cf777e6,
    name: "mobile-activity-activity_old"
  }, {
    path: "/mobile/event/FIFA2022",
    component: _d947689c,
    name: "mobile-event-FIFA2022"
  }, {
    path: "/a/:code",
    component: _3de885b5,
    name: "a-code"
  }, {
    path: "/",
    component: _70a9cae6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

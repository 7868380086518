export default {
	"help_gambling_tips": "Gambling Tips",

	"auto_transfer": "auto transfer",
	"bind": "bind",
	"bind_line": "Bind Line",
	"has_registered_account": "Is there an account already registered?",
	"no": "no",
	"unbind": "unbind",
	"yes": "yes",

	"bind_0": "unbound",
	"bind_1": "has bound",
	"status_0": "Processing",
	"status_1": "Complished handling",
	"status_2": "Rejected",
	"stroe_IBONS": "7-11",
	"stroe_FAMILYS": "Familys",
	"stroe_HILIFE": "Hi-Life",
	"stroe_OK": "OK Mart",
	"stroe_OKMART": "OK Mart",
	"pay_type_BankTransfer": "Transfer to Bank Account",
	"pay_type_LinePay": "iPASS Money",
	"pay_type_JkoPay": "JKOpay",
	"pay_type_Virtual": "Virtual Pay",
	"pay_type_ThirdParty": "Payment",
	"pay_type_ThirdParty_1": "Transfer to Bank Account",
	"pay_type_ThirdParty_2": "iPASS Money",
	"pay_type_ThirdParty_4": "JKOpay",
	"pay_type_ThirdParty_8": "Conveninent store paymente",
	"pay_type_ThirdParty_16": "Qr Code",
	"pay_type_ThirdParty_32": "ATM transfer",
	"pay_type_ThirdParty_64": "Online Banking",
	"pay_type_ThirdParty_256": "Paytm",
	"pay_type_ThirdParty_512": "UPI",
	"pay_type_ThirdParty_1024": "GCash",
	"pay_type_ThirdParty_2048": "PayMaya",
	"pay_type_ThirdParty_4096": "Virtual Pay",

	"all": "All",
	"all_games": "All games",
	"all_activity": "All Discount",
	"audio": "audio",
	"bind_bankcard": "Please go tied with the bank card",
	"bind_jkopay": "Please go tied with the JkoPay",
	"bind_linepay": "Please go tied with the iPASS money",
	"bind_virtualwallet": "Please go tied with the cryptocurrency wallet address",
	"bind_usercard": "Please go ahead to bind with your ID card",
	"dont_discount": "Not using discount",
	"examination_passed": "Passed",
	"landscape": "Turn your phone, thanks.",
	"not_approved": "not approved",
	"under_review": "Pending",
	"virtual_currency_channel": "Virtual currency channel",

	"details": "Detail",
	"illustrate": "Instruction",
	"tab_third_upi": "UPI",
	"tab_third_gcash": "GCash",
	"tab_third_paymaya": "PayMaya",
	"deposit_payupi": "UPI ID",
	"deposit_plz_enter_upi": "Please enter UPI ID",
	"header_top_qa": "How to Deposit?",
	"header_top_line": "Service",
	"header_top_info": "FAQ",

	"tip": "Reminder",
	"announcement": "Notice",
	"system_title": "Latest News",
	"announcement_type": "Classify notice",
	"item": "Item",
	"all_item": "ALL",
	"all_item_a": "System notice",
	"all_item_b": "Promotion",
	"now": "In page",
	"page": "",
	"nomsg": "No Information",
	"type": "Type",
	"address": "Address",
	"status": "Status",
	"soon1": "Coming",
	"soon2": "Soon",
	"soon3": "",
	"soon4": "",

	"order_id": "Txn. ID",
	"created_at": "Deposit Time",
	"status_name": "Status",
	"fields_complete_at": "Finished time",
	"fields_demand": "Line production demand",
	"fields_valid": "Effective line production ",
	"retreat": "Rebate",
	"total": "Total",
	"odd_number": "Odd unmber",
	"single_number": "Insurance ID",
	"beting": "Betting Amount",
	"amount_bet": "Betting Amount",
	"vaild_bet": "Valid Bets",
	"content_bet": "Betting content",
	"time_bet": "Bet Time",
	"winlose": "Win/Loss",
	"platform_from": "Tranfer out platform",
	"platform_to": "Transfer in platform",
	"points": "Points",
	"friend_num": "Total Referrals",
	"friend_list": "Friends list",
	"plz_select_friend": "Please select friends",
	"amount_share": "Rebate",
	"first": "First level",
	"second": "Second level",
	"valid_first": "Valid bet of first level",
	"valid_second": "Valid bet of second level",
	"amount_share_first": "First level rebate",
	"amount_share_second": "Second level rebate",
	"fee": "Fee",
	"friend_shardmode": 'Friend share mode, recommend your friends, no matter lose or win, there will be "1/1000" feedback.',
	"friend_shard_unlimit": "Unlimited friends sharing amount, share more, earn more",
	"friend_copy": "invite your friends to join us now!",
	"friend_promotion_url": "Copy code",
	"shareReport": "View records",
	"hisbetReport": "Game REC",
	"user_hisSearch": "Records search",
	"user_histransfer": "Point giveaway REC",

	"money_transfer": "Transfer to Bank Account",
	"credit_card": "Credit Card",
	"paytm": "Paytm",
	"ATM": "ATM",
	"webatm": "Online Banking",
	"third_atm": "ATM transfer",
	"third_qrcode": "Qr Code",
	"linepay": "iPASS Money",
	"linepaymoney": "iPASS Money",
	"jkopay": "JKOpay",
	"payItem_6_subtitle": "Average getting point about 1 min",
	"ibon_pay": "7-11 ibon print and pay",
	"famiport_pay": "FamiPort print and pay",
	"eighty_seven_percent": "There are 87% people",
	"choose_this_option": "Are using this way",
	"start": "Start using",
	"downloadPic": "Download QRcode",
	"fields_account": "Accounting item",
	"fields_about": "Related Infromation ",
	"table_order_date": "Order date",
	"table_expire_date": "Payment deadline",

	"no_data": "No Information",
	"no_record": "No Information",
	"login_invalid": "Login failed!",
	"error_sorry": "Sorry, error pages",
	"error_back_home": "Back to the home page",
	"back": "Return",
	"search": "Search",
	"operating": "Operation ",
	"uphold_ing": "Under Maintenance",
	"hotpro_tip": "More",
	"hotpro_title": "Hot products",
	"hotpro_menu": "Setting",
	"hotpro_menu2": "ME",
	"hotpro_hello": ", Hello",
	"hotpro_switch": "On Left",
	"profile": "Profile",
	"hisBet": "Hisotry",
	"home": "Home",
	"just8899": "just8899",
	"jackpot539": "539",
	"activity": "Promotion Posting",
	"share": "Share",
	"card_manage": "Band Card",
	"bind_limit": "Has been the limited",
	"card_bind_limit": "Has been the limited for the tied cards.",
	"card_bind_view": "Check the tied cards",
	"bind_view": "View",
	"receiptview": "Check receipe",
	"web_msg": "Messages",
	"web_mail": "Messages",

	"sport": "Sports",
	"live": "Lives",
	"egame": "E-Game",
	"lotto": "Lottery",
	"chess": "Chess",
	"fish": "Fish",
	"animal": "Animal",

	"game_sport": "Sports",
	"game_live": "Lives",
	"game_egame": "E-Game",
	"game_lotto": "Lottery",
	"game_chess": "Chess",
	"game_fish": "Fish",
	"game_animal": "Animal",

	"s_hot": "Hot",
	"s_sport": "Sports",
	"s_live": "Lives",
	"s_egame": "E-Game",
	"s_lotto": "Lottery",
	"s_chess": "Chess",
	"s_animal": "Animal",
	"s_fish": "Fish",
	"s_slot": "Slot",
	"s_slot2": "Slot",
	"s_together": "Multiple",
	"s_jackpot": "Jackpot",

	"blog": "Blog",
	"jackpot": "Jackpot",
	"jackpot_list": "",
	"jackpot_in": "",
	"jackpot_geting": "",
	"jackpot_get": "Get",
	"jackpot_dollar": "point",
	"recommend": "REC",
	"s_desopit": "Deposit",
	"s_turning": "Tranfer",
	"s_coin": "Give points",
	"s_wallet": "Wallet",
	"s_share": "Share",

	"user_approve": "Authentication",
	"bind_data": "Tied info",
	"learn_more": "More",
	"share_money": "Share Point",
	"help_video": "Video Describe",
	"help_question": "FAQ",
	"help_download": "APP Download",
  "help_download_ios": "IOS Download",
  "help_download_and": "android Download",
	"help_novice": "Novice Help",
	"help_game": "Help Center",
	"help_check": "Clear cache",
	"help_newspaper": "Casino News",
	"help_champion": "539",
	"help_comp": "Corporate Affairs",
	"game_type": "Classify games",
	"game_platform": "Provider",
	"precautions": "Notice ",

	"footer_declare": `The "Game Points" on the website are only for amusment, can't sale the points with cash and change bonus products.`,
	"footer01_title": "Simple process",
	"footer01_sub": "3 mins deposit and withdraw",
	"footer02_title": "product service",
	"footer02_sub": "The most abundance amusement",
	"footer03_title": "Personal  interface",
	"footer03_sub": "Persue experience user supreme",
	"footer04_title": "Professional brand",
	"footer04_sub": "Company credibility worth to trust",
	"footer05_title": "User praise",
	"footer05_sub": "User praise recommend",
	"partner": "Partner",
	"using_help": "Help & Support",
	"info_help": "Help & Support",
	"allday_service": "24 hours service information",
	"phone_bet": "Cellphone betting",
	"ios": "IOS",
	"android": "Android",

	"wallets": "Wallet",
	"DG": "DG",
	"AB": "AB",
	"SA": "SA",
	"WM": "WM",
	"SUPER": "SUPER",
	"101": "101",
	"RG": "RG",
	"BWIN": "BWIN",
	"KA": "KA",
	"fish_GR": "GR fish",
	"fish_BWIN": "BWIN fish",
	"fish_OB": "PM fish",
	"fish_ZG": "ZG fish",
	"fish_RSG": "RSG fish",
	"hot_allbet": "AB live",
	"hot_DG": "DG live",
	"hot_OB": "PM live",
	"hot_WG": "WG lotto",
	"hot_ZG": "ZG slot",
	"hot_OB_sport": "PM sport",
	"hot_OB_game": "PM slot",
	"hot_WG": "WG lotto",
	"hot_RSG": "RSG slot",
	"hot_BOBHH": "BOBHH chess",

	"uphold_time": "Maintenance Information",
	"maintain_list": [
		{
			"title": "Allbet",
			"date": "Monday",
			"time": "08:00~12:00"
		},
		{
			"title": "SA",
			"date": "Monday",
			"time": "11:00~13:30"
		},
		{
			"title": "WM",
			"date": "Monday",
			"time": "11:00~13:30"
		},
		{
			"title": "SUPER",
			"date": "Monday",
			"time": "12:30~16:00"
		},
		{
			"title": "DG",
			"date": "Wednesday",
			"time": "06:30~10:30"
		}
	],

	"normal": "Normal",
	"stop_using": "Disabled",
	"today": "Today",
	"yesterday": "Yesterday",
	"lastweek": "Last Week",
	"thisweek": "This Week",
	"lastmonth": "Last Month",
	"thismonth": "This Month",
	"startdate": "Start time",
	"deadline": "End time",
	"platform": "Game Type / Games Platform",
	"plz_select_feedback": "Please select",
	"account_number": "ID card numbers",
	"plz_account_number": "Please enter ID card number",

	"no_operating_authority": "This function is out of the authorize!!",
	"failed_request_plz_try_again": "Requst fail, please try again later",
	"upload_limit_over": "Over the limitation of uploading file",
	"click_bank_name_copy": "※Warm remider: Click <b><u>bank Account Number</u></b> and <b><u>account name</u></b> now can copy.",
	"fill_field": "Please fill in this field",
	"fill_field_correctly": "Please fill in this field correctly",
	"plz_enter_username": "Please enter player ID.(cellphone number)",
	"plz_enter_coin_num": "Please enter the amount of coins",
	"plz_enter_donate_points": "Please enter Point giveaway account.",
	"plz_enter_points_num": "Please enter point giveaway",
	"plz_enter_user_mobile": "Please enter player ID with cellphone number.",
	"plz_enter_CAPTCHA": "Please enter Validation code.",
	"plz_linpay_account": "Please enter iPASS money account.",
	"plz_jkopay_account": "Please enter JKOPay account.",
	"gift_coins": "Amount of coins given",

	"tip_captchas_required": "Empty or format error.",
	"tip_donate_cant_required": "Empty or format error.",
	"tip_cant_required": "Can't be empty or wrong format.",
	"tip_cant_captcha_required": "The validation code you entered is incorrect, please try again.",
	"get_agree": "I am at least 18 years of age and I have read, accept and agree to all the ",
	"i_have_read": "I have read the following ",
	"terms": "Terms and Conditions.",
	"tip_mobile_captcha_first": "Mobile authentication code has been sent to you phone number.",
	"tip_plz_agree": "Please agree the related rules and service conditions.",
	"tip_id_ok": "The account is checked successfully.",
	"tip_mobile_captcha_send": "Mobile authentication code has been sent to you phone number.",
	"tip_mobile_second_reset": "Send again in a sec.",

	"dialogTipDigit_main_info": "Please mainrain the complete infromation, can't lose the corner, edges/ reflection / blurring/ covering the personal information.",
	"how_light": "How to avoid refelction?",
	"how_light_c1": "Chose the place that has enough light to take the pictures.",
	"how_light_c2": "Do not use flash.",
	"how_light_c3": "Make the thing you take the picture of upright to avoid the white reflection that cause by the light.",
	"how_blur": "How to avoid blurry?",
	"how_blur_c1": "Focus before taking the pictures, click the screen, camera, and push the shutter bottom.",
	"how_blur_c2": "Take the picture or scan  the original one, not the printed one.",
	"tip_ok_sample": "Accurate sample",
	"tip_ok_c1": "Identity card",
	"tip_ok_c2": "bank book",
	"tip_exp": "Reference example",
	"tip_error_sample": "error example",
	"tip_error_c1": "Cut corner or edge",
	"tip_error_c2": "Cut the Identification corner",
	"tip_error_c3": "Can't identify",
	"tip_error_c4": "Parts of the refelction",
	"tip_error_c5": "Reflection on the right bottom",
	"tip_error_c6": "blur",
	"dialogTipDigit_ok_c1": "Identity card",
	"dialogTipDigit_ok_c2": "bank book",
	"dialogTipEntity_ok_c1": "Identity card",
	"dialogTipEntity_ok_c2": "bank book",

	"bw8_li_01": "請在點數未上分前【保留您的繳費收據】，以保障您的權益。",
	"bw8_li_02": "如您是使用【信用卡】方式儲值，將【收取10%手續費】，舉例:您儲值1,000元，實際獲得的遊戲點數為900點。",
	"bw8_li_03": "單號成立後請務必於【2小時內】繳費入款 ，超過時限單號即失效，提交單號後若超過2小時，請再重新提交入款單。",
	"bw8_li_04": "每組儲值繳費單號為【單次繳費使用】請勿重複繳款。",
	"bw8_li_05": "儲值時請繳申請時之【正確】的金額，假設您輸入儲值5,000，繳款時請繳款5,000元整，避免導致未收到款項。",
	"bw8_li_06": "如您【先前已有申請儲值】，但是尚未完成交易之情形，無法再次申請儲值，如需儲值，請洽客服協助再次開單。",
	"bw8_li_07": "如您有申請託售，並在【託售未處理完畢前】，請勿再申請儲值以免造成您權益上的損失。",
	"bw8_li_08": "託售時須達到儲值金額【一倍洗碼量】方可進行託售，如未達洗碼標準將無法成功託售。",
	"bw8_li_09": "若利用本娛樂城進行任何洗錢詐騙行為，本娛樂城有權利永久終止會員服務，不另行通知。",
	"bw8_li_10": "【請確實填寫本人手機號碼】避免儲值管道要求手機驗證，系統偵測錯誤號碼導致通道關閉。",

	"casino_introduce": "Top Games",
	"hot": "User praise recommend",
	"play_together": "Multiple",
	"fighting": "Battle",
	"slot": "Slot",
	"slot2": "Slot",
	"fishing": "Fish",
	"together": "Multiple",

	"slot_description": "-Slot game, slot game, slot machine table, online slot machine, slot machine, slot, game",
	"chess_description": "-Dragon Tiger, Niuniu, Niuniu, Fried Golden Flower, Online Baccarat, Fan Tan, Color Disc, Chess and Card Games, Chess and Card Games",
	"live_description": "-Ober, Salon, WM, DG, Live Baccarat, Online Baccarat, Baccarat Game, Baccarat Make Money, Baccarat Win Money",
	"lottery_description": "-539, Tiantianle, Grand Lotto, Mark Six, 539 cards, underground 539 gameplay, 234 stars, full car, car ride, station number, no license, multiple choice not, optional choice, Beijing Motorsport, Lucky Airship, Hurry Airship, bingo bingo, time color",
	"egame_description": "-Slot game, slot game, slot machine table, online slot machine, slot machine, slot, game",
	"sport_description": "-NBA, CBA, KBL, MLB UEFA Champions League, World Football Cup, Lucky Lottery, Lucky Lottery Betting, Lucky Lottery How to Buy, Lucky Lottery, Play Lottery, Sports Roll, Taiwan Lottery, Lottery Aykon ,Yuncai betting station",
	"animal_description": " -animal",

	"about_title01": "About Us",
	"agent_title01": "Agent",

	"check_title": "Clear cache",
	"check_list": [
		{
			"imgPath": "ios-clear-history",
			"title": "Clear the history and cookies from Safari on your iPhone, iPad, or iPod touch",
			"cont1": "Learn how to delete your history, cookies, and cache in Settings.",
			"cont2": "Here's how to clear information from your device:",
			"cont3": "To clear your history and cookies, go to Settings > Safari, and tap Clear History and Website Data. Clearing your history, cookies, and browsing data from Safari won't change your AutoFill information.",
			"cont4": "To clear your cookies and keep your history, go to Settings > Safari > Advanced > Website Data, then tap Remove All Website Data.",
			"cont5": "To visit sites without leaving a history, turn private browsing on or off."
		},
		{
			"imgPath": "android-clear-history",
			"title": "Clear the history and cookies from Chrome on your Android touch",
			"cont1": "If you remove cookies, you'll be signed out of websites and your saved preferences could be deleted.",
			"cont2": "Here's how to clear information from your device:",
			"cont3": "On your Android phone or tablet, open the Chrome app.",
			"cont4": "To the right of the address bar, tap More More and then Settings.",
			"cont5": "Tap Privacy and then Clear browsing data.",
			"cont6": "Choose a time range, like Last hour or All time.",
			"cont7": "Check 'Cookies, media licenses and site data.' Uncheck all the other items.",
			"cont8": "Tap Clear data and then Clear.",
		}
	],

	"disclaimer_title": "Privacy Policy",
	"disclaimer_list": [
		{
			"title": "Privacy",
			"cont1": "We are committed to protecting the privacy of our customers and providing the safest gaming platform. The information we collect on this website will provide you with the most excellent service. We will not sell or rent your personal information to third parties. Personal data are processed with encryption technology and stored in a secure, non-public operating system. For assistance partners who have the opportunity to contact customers' personal data, they must also comply with the privacy and confidentiality rules that we have established.",
			"cont2": "You need to keep your account and password safe. Do not allow any other person or third party, including any minors, to use or reuse your account for any website visits and betting operations. The company will have the right to refuse to pay any rewards or bonuses arising therefrom. You will be solely responsible for all losses incurred by the third party in your account.",
			"cont3": "You may receive some regular letters or newsletters to update you on the information and services of our company's products. When this website launches new, fun and interesting promotional activities, these letters or newsletters can help you fully enjoy this website. Service.",
			"cont4": "Our system will automatically record the Internet IP address of the visitor, but we will never record the email address of the website visitor, and this item is mainly used for website traffic statistics.",
			"cont5": "The company reserves the right to make any changes to the privacy policy on its own. Any changes to this policy are binding and take effect immediately.",
			"cont6": "The casino will never disclose your personal information to any third party, unless it receives court flyers or feasible legal requirements and judgments.",
			"cont7": "We have the right to provide necessary personal information to relevant payment platform service providers and financial insurance institutions to complete payment requirements through the website.",
			"cont8": "All personal information provided by users will be transmitted through a secure port (SSL 128 bit encryption Standard) and stored in a confidential environment inaccessible to the public. All internal access to data is restricted and strictly monitored.",
			"cont9": "If you have any other questions, please contact our 24-hour online customer service."
		},
		{
			"title": "safety",
			"cont1": "The company's website is one of the most advanced online gambling institutions in the world, committed to providing a legal and standardized online gambling environment. In addition, we take all safety precautions and install good technology equipment to ensure that only qualified players are provided with services. Users in areas where online gambling is prohibited are not allowed to place bets.",
			"cont2": "We also use the best encryption technology and firewall system to protect your safety and privacy, and to ensure that you enjoy a fair game. Thanks to the use of enterprise-level encryption technology (1024-bit RSA key exchange and 448-bit blowfish), we can provide your data with higher protection than general commercial-level SSL. This technology is currently adopted by major financial institutions in the world.",
			"cont3": "All banking transactions are carried out by international financial institutions in a high-standard security and confidential network. The player’s unique login ID and password are also required to enter the player’s account information. We will never allow any fraud on our website. Any such behavior will result in the account being immediately closed, all winnings will be confiscated, and perhaps civil or criminal proceedings will be initiated against the player.",
			"danger": "The company always puts the protection of players' rights and interests first."
		}
	],

	"add_app_desktop": "Add APP iCon to the table",
	"add_android_title": "Add Chrome widget to your home screen",
	"add_android_step1": "Click upper right icon on the browser.",
	"add_android_step2": `Chose"Add to main page", then finish.`,
	"dont_show": "No more appear this question.",
	"add_app_ios_title": "Use Safari to open the website, then can put this website to the cellphone page, let you connect our website faster.",
	"add_ios_step1": "Please click bottom of the browser.",
	"add_ios_step2": `Click "Add" to table.`,
	"add_ios_step3": `Click "Add" , finish.`,

	"btn": {
		"showhidden": "Show / Hide",
		"send_refresh": "Refresh",
		"send_new_pw": "Send new password",
		"get_CAPTCHA": "Submit",
		"verify_btn": "Resend",
		"error_feedback": "It is wrong, please try again.",
		"cancel_edit": "Enter again",
		"submit_confirm": "Submit",
		"submit_check": "Submit",
		"ok": "Submit",
		"submit": "Confirm",
		"confirm": "Confirm",
		"cancel": "Cancel",
		"reset": "Reset",
		"reset_again": "Reset",
		"login": "Login",
		"login_en": "LOGIN",
		"signup": "Join Now",
		"signup2": "Register",
		"header_login_signup": "Register and log in",
		"sign": "Join Now",
		"agent_login": "Agent Login",
		"agent_sign": "Agent registration",
		"sign_en": "JOIN NOW",
		"logout": "Logout",
		"user_login": "Login",
		"user_verify": "User Verify",
		"sign_up": "Register Now",
		"join_now": "Yet to register?",
		"delete": "Delete",
		"copy": "Copy",
		"close": "Close",
		"close_all": "Close all",
		"search": "Search",
		"select_all": "ALL",
		"enter_game": "Play",
		"play_game_now": "Play Now",
		"free_try_play": "Try now",
		"try_play": "Try now",
		"CAPTCHA": "Validation code",
		"turnpoint": "Submit"
	},

	"withdrawal": {
		"withdrawal": "Withdrawal",
		"treasure_trial": "Consignment trial calculation",
		"treasure_note": "Precautions on consignment",
		"wallet_treasure": "Withdraw",
		"treasure_records": "Consign REC",
		"no_sale_record": "No Information",
		"treasure_bank_name": "Transfer from",
		"treasure_amount": "Transfer Amount",
		"treasure_bank_card": "The bank cards of consignment.",
		"choose_bank_feedback": "Please select the bank cards of consignment.",
		"treasure_point": "Requested Amount",
		"treasure_point_limit": "The consign points need between",
		"plz_treasure_point": "Please enter consign points.",
		"treasure_precautions": `"Consignment notice"`,
		"plz_treasure_agree": `Please agree with the "Consignment notice".`,
		"note_1": "Three time withdraw limitation, free handling fee three times a week, 1% of the withdraw amount plus 50 dollars as the handling fee for the forth time. (Every Monday 00:00~Sunday 23:59)",
		"note_2": "If using this amusement to money laundering or fraud, this amusement has the right to stop member service forever without notice.",
		"note_3": "If the monitor apartment finds out any person or group using dishonest ways to get profits, same accout/ family/ phone number/ deposit place/ bank account/ the sme or familiar IP/ internet ..., the company has the right to keep, cancel or recycle the unjust profits.",
		"note_4": "Account apartment consigning time is about 15-30 mins.",
	},

	"desopit": {
		"desopit": "Deposit",
		"desopit_tycc": "Top up",
		"fields_order_id": "Deposit number",
		"fields_type": "Payment Type",
		"desopit_amount": "Deposit Amount",
		"hello_desopit_info": "Hi, the following is your deposit information:",
		"desopit_qrcode": "QR code payment",
		"desopit_mode": "Deposit Type",
		"desopit_account": "deposit account",
		"desopit_code": "deposit code",
		"desopit_now": "Deposit",
		"desopit_tyc": "Top up right away",
		"choose_paybank_select": "--- Please select the bank debit card to top up ---",
		"choose_paybank_feedback": "Please select the bank debit card to top up",
		"choose_paystore": "Please select",
		"desopit_bank_card": "Top up bank card",
		"point_desopit": "Deposit",
		"desopit_point": "Amount",
		"plz_enter_point": "Please enter deposit points",
		"desopit_limit": "The deposit points should between",
		"desopit_point_info": "Deposit points information",
		"plz_deposit_receipt": `Please upload "deposit receipt"`,
		"upload_deposit_receipt": "Upload deposit receipt",
		"deposit_receipt": "Deposit receipt",
		"deposit_records": "Deposit REC",
		"no_deposit_records": "No Information",
		"deposit_detail": "Detail",
		"deposit_others": "Others",
		"crypto_address": "Crypto Address",
		"wallet_address": "Wallet Address",
		"plz_choose_crypto_address_feedback": "Please select",
		"plz_choose_crypto_address_select": "--- Please select ---",
		"pay_virtual_currency": "Deposit Crypto",
		"plz_enter_crypto": "Please enter",
		"cancel": "cancel desopit"
	},

	"discount": {
		"activity": "Discount",
		"choose_discount_select": "--- Please select ---",
		"choose_discount_feedback": "Please select discount",
		"records": "Promotion",
		"apply": "Discount Application",
		"btnYes": "I am in",
		"time": "Promotion Period",
		"object": "Promote object",
		"content": "Promote content",
		"rule": "Terms and Conditions",
		"act_name": "Promotion Name",
		"amount": "Bonus Amount",
		"mode": "Discount type",
		"name": "Discount name",
		"description": "Discount description",
		"nothing": "No information found!",
		"search": "Search",
		"type": "Discount type",
		"type_1": "Local deposit discount",
		"type_2": "Third-party payment discount",
		"type_4": "Self-help application discount",
		"application": "discount application",
		"applied": "discount applied",
		"first": "First Reserve Limit",
		"full": "Number of applicants is full",
		"doing_d": "Promote has finished",
		"doing_o": "",
		"doing_i": "",
		"doing_n": "",
		"doing_g": ""
	},

	"wallet": {
		"name": "Name",
		"plz_choose_out": `Please select "Transfer money out"`,
		"plz_choose_in": `Please select "Transfer money in"`,
		"back_wallet": "One click recover",
		"digital_wallet": "Wallet",
		"wallet_balance": "Wallet balance",
		"wallet_address": "Wallet Address",
		"plz_input_wallet_address": "Please enter wallet address",
		"deposit_wallet_address": "Wallet Address",
		"tranfer_wallet_address": "Wallet Address",
		"enter_ob_tip": "Entering the {game} game, the points are displayed at 5:1. For example, if the main wallet has 1000 points, entering the {game} will display 200. The transfer back to the game lobby will also be exchanged according to this ratio. For example, the {game} balance is 200, and the transfer back to the main wallet is 1000 points.",
		"enter_exch_tip_10": "Entering the {game} game, the points are displayed at 10:1. For example, if the main wallet has 1000 points, entering the {game} will display 100. The transfer back to the game lobby will also be exchanged according to this ratio. For example, the {game} balance is 100, and the transfer back to the main wallet is 1000 points.",
		"mobile_wallet_info": "Mobile payment information（Please enter the information to be bound, once bound, all withdrawals will be automatically remitted to this mobile wallet）",
		"choose_transfer_virtual_address_select": "--- Please select ---",
	},

	"bank": {
		"table_code": "deposit code",
		"bank_name": "Bank Name",
		"bank_user": "Account Name",
		"bank_number": "Bank Account Number",
		"bank_book": "bank book",
		"choose_transferbank_select": "--- Please select transfer bank ---",
		"choose_transferbank_feedback": "Please select transfer bank",
		"transfer_bank": "bank account number",
		"transfer_bank_info": "To change the bank information, please contact the customer service. The account name should be the same as the register name and ID name. Need name certificate for consignment, If notthe same, can't consign.(can't change name)",
		"bank_transfer_info": "The account name must be consistent with the registered name/ID name, and the same person can only use one account. If the name is different when withdrawing money, the withdrawal cannot be made (the name cannot be changed)",
		"only_bind_bank": "Only accept the transfter payment by tied bank",
		"bind_bank": "Tied with bank",
		"bankcard_info": "Bank Account",
		"bank_branch": "Branch",
		"bank_branch_open": "IFSC",
		"choose_bankcard_select": "--- Please select the bank cards ---",
		"bind_bank_account": "Tied with the bank account",
		"bank_card_info": "Bank card information(Please enter the tied information, as soon as you tied up, all the withdral will transfer in this bank card)",
		"plz_accurately_info": "The bank card that is tied up is used for the consignment. To deal with the distribute for your consignment, please make sure you write the correct account information.",
		"bankuser_cannot_over": "Bank account name can't be over 50 words",
		"bankuser_cannot_required": "Bank account name can't be empty or wrong format.",
		"plz_select_bank": "Please select",
		"bank_number_required": "Bank account number can't be empty or wrong format.",
		"bank_branch_required": "Branch name can't be empty or wrong format.",
		"plz_transfer_bank": "Please go to the assigned bank account to transfer.",
		"transfer_oldbank_not_check": "If transfer to the old bank account, it can't be received, ",
		"not_responsible": "no responsibility for that!",
		"the_website": "This website ",
		"not_atm_card_cash": "can't accept the way that ATM cash deposit without card.",
		"plz_transfer_remittance": "Please use transfer with  the bank account, in order not to delay your time",
	},

	"transfer": {
		"third_atm": "ATM transfer",
		"user": "Account",
		"account": "Personal Account",
		"submit_after": "Submit after transfer",
		"linepay_tip": "※Please screenshots QR code and then transfter on Line",
		"jkopay_tip": "※Please screenshots QR code and then scan the JKO Pay and transfer",
		"qrcode_tip": "※Please screenshots QR code and then scan the APP to transfer",
		"already": "Money transfer already",
		"been_transferred": "Money transfer already",
		"virtual_address": "Transfer to",
		"only_virtual_address": "Only accept the transfter payment by Crypto Address",
		"point_records": "Transfer points REC",
		"no_turning_point": "No Information",
		"turnaround_time": "Transfer points time",
		"iwant": "I want to go from",
		"goto": "Tranfer points to",
		"enterpoint": "Please enter switch points",
		"onlyInteger": "Please enter switch points, integer only",
		"turn_point": "Quota transfer",
		"points_records": "Tranfer",
		"turning_point": "Point giveaway",
		"turning_coin_record": "Point giveaway REC",
		"turning_coin": "Gift Gold Coins",
		"have_coin": "Have gold coins",
		"turning_reduce_fees": "Transfer fee will be deducted",
		"maximum_coin": "The maximum amount of a single transfer",
		"minimum_coin": "The minimum amount of a single transfer",
		"donate_point_member": "Member Point giveaway",
		"donate_point_account": "Point giveaway account",
		"donate_points": "Point giveaway amount",
		"donate_points_limit": "Empty or format error.",
		"created_at_transfer": "Point giveaway time",
		"transfer_mode": "Point giveaway type",
	},

	"stroe": {
		"store_barcode": "Conveninent barcode",
		"store_ibon": "7-11",
		"store_familys": "Familys",
		"store_ok": "OK mart",
		"store_hilife": "Hi-Life",
		"third_store": "Conveninent store paymente",
		"plz_save_store_receipt": "Take the pictures of the transfer receipt after pay in conveninent stores, won't give the points without the receipt.",
		"store_name": "Convenience store name",
		"store_code": "Convenience store code",
	},

	"category_0": "HOT",
	"category_999": "Fish",

	"customer": {
		"online_hi": "Hi~ May I help you?",
		"h24": "24 hours",
		"h24_service": "24 hours online consulation for your service",
		"service": "Customer Service",
		"service_contact": "Contact",
		"service_line": "LINE",
		"line_service": "LINE",
		"contact_us": "Contact us",
		"uh_oh": "UH-OH!",
		"plz_contact_us": "Please contact customer services",
		"online_howru": "Please add Customer Service Line",
		"service_tw": "Online Customer Service",
		"service_en": "Online Service",
		"contact_service_center": "If there is any problems, welcome to contact customer service",
		"service_profile_info": "Can't change information that have already provided. Please change information by contacting the Customer Service.",
		"service_contact_upload": "This website in under maintaining, sorry to cause any inconveninent,If there is any problems, please contact customer service",
		"telegram": "Telegram",
		"whatsapp": "WhatsApp",
		"facebook": "Facebook",
		"instagram": "Instagram",
		"twitter": "Twitter",
		"maintenance_title1": "",
		"maintenance_title2": "",
		"maintenance_title3": "",
		"maintenance_title4": "",
		"maintenance_title5": "",
		"maintenance_title6": "",
		"maintenance_title7": "",
	},

	"copy": {
		"friend_copy": "invite your friends to join us now!",
		"friend_promotion_url": "Copy code",
		"copy_Succe": "Succeeded",
		"copy_error": "Failed",
	},

	"upload": {
		"receipt": "Upload payslip",
		"not_receipt": "No upload receipe.",
		"tip_prompt_sample": "Upload sample",
		"tip_digit_sample": "Upload picture sample",
		"identity_title": "ID card uploading example",
		"identity_main_content": "When you choose taking pictures or uploading ID card (unnecessary to upload,only when the platform considers it’s needed,then CS will ask for it for example: same last name, same first name)Please notice that:",
		"tip_digit_content": "When you chose taking the picture or scanning to uplaod ID/bank book, please notice the following things.",
		"tip_entity_sample": "Upload picture sample",
		"tip_entity_content": "When you chose taking the picture or scanning to uplaod ID/bank book, please notice the following things.",
		"tip_prompt_content": "When you chose taking the picture or scanning to uplaod ID/bank book, please notice the following things. ",
		"upload_exp": "Upload sample",
		"plz_upload_pic": "Please upload a photo",
		"only_img_upload": "Upload only pictures",
		"not_pic": "Non pictures.",
		"only_three_pic": "Limit three pictures",
		"plz_upload_profile": `Please upload "Personal namecard"`,
		"plz_jkopay_profile": "Please upload JKO personal namecard.",
		"upload_limit_over": "Over the limitation of uploading file",
		"plz_number_profile": "Please upload your ID card",
		"plz_upload_profile_front": "Please upload ID card (front page)",
		"plz_upload_profile_reverse": "Please upload ID card (back page)",
		"plz_upload_bank_passbook": "Please upload original",
		"plz_upload_id_card": "Please upload original",
	},

	"pw": {
		"plz_enter_safe_code": "Enter secure password",
		"safe_code": "Secure password",
		"forgot": "Forgot password",
		"forgot_mark": "Forgot password?",
		"ButSendCode_newpw": "Send new password",
		"ButSendCode_reset": "Send again in a sec",
		"tip_idpw_not_same": "Must Different From Player Id",
		"password": "Password",
		"confirmation": "Confirm Password",
		"safe_pw_feedback": "Empty or format error.",
		"user_pw": "Password",
		"user_chgpw": "Change Password",
		"chgsafepw": "Change secure password",
		"old_pw": "Current Password",
		"old_pw_feedback": "Characters length must be in valid range 6 ~ 20.",
		"new_pw": "New Password",
		"new_pw_feedback": "Characters length must be in valid range 6 ~ 20.",
		"new_pw_again": "Confirmed New Password",
		"new_pw_again_feedback": "Please confirm the new password again.",
		"set_safe_pw": "Change secure password",
		"edit_safe_pw": "Change secure password",
		"old_safe_pw": "Current secure Password",
		"old_safe_pw_feedback": "Can't be empty or wrong format.",
		"new_safe_pw": "Confirmed New Password",
		"new_safe_pw_feedback": "Please confirm the new password again."
	},

  "vip": {
    "vip_freeback":"VIP"
  },

	"virtual": {
    "buycoin_view": "View for Buying Crypto",
		"channel": "Protocol",
		"name": "Virtual Name",
		"bitcoin": "Virtual Currency",
		"account": "Virtual account",
		"currency": "Cryptocurrency",
		"tied_crypto": "Tied with Crypto",
		"currency_title": "Cryptocurrency",
		"virtual_address": "The virtual currency address of the consignment",
		"choose_address_select": "--- Please select a virtual currency address ---",
		"choose_address_feedback": "Please select the virtual currency address for consignment",
		"currency_limit": "The points should between",
		"currency_coin_limit": "The virtual currency should between",
		"treasure_currency": "consignment virtual currency",
		"treasure_currency_limit": "The consignment virtual currency needs to be between",
		"plz_treasure_currency": "Please enter the consignment virtual currency",
		"plz_input_name": "Please select",
		"plz_select_channel": "Please select",
		"plz_upload_profile": "Please upload 【wallet address】",
		"wallet_address": "Wallet Address",
		"exchangeRate": "FX rate",
		"exchangeRatePoint": "Change Point",
		"pay": "Crypto Pay",
	},

	"pay": {
		"payment": "Digital Payments",
		"proceed": "Click to deposit",
		"qrcode": "Qr Code",
		"tide_digital": "Digital Payments",
		"linepay_account": "iPASS Money account",
		"jkopay_account": "JKOPay account",
		"mobile_pay_info": "Mobile payment  information (Please contact customer service if you want to change)",
		"mobile_wallet_info": "Mobile payment  information(Enter the information you want to tie, once you tied, all the withdral will transfer to this elecronic purse)",
		"linepay": "iPASS Money",
		"mobile_pay_required": "Mobile payment can't be empty or wrong format.",
		"third": "Third-party payment discount",
		"plz_linepay_profile": "Please upload iPASS Money account",
		"plz_upload_linepay": `Please upload "iPASS Money account" screenshot`,
	},

	"user": {
		"join": "Join Now",
		"join_agent": "Join Agent",
		"agent": "Agent ID",
		"agent_account": "Agent ID",
		"id": "Player ID",
		"account": "Account",
		"password": "Password",
		"password_confirmation": "Confirm Password",
		"email": "Email",
		"name": "Real Name",
		"w_code": "Security Code",
		"birthday": "Date of Birth",
		"nickname": "Nickname",
		"realname": "Real Name",
		"user_email": "Email",
		"coin": "Wallet balance",
		"mobile": "Contact Number",
		"line_id": "Line ID",
		"telegram_id": "Telegram ID",
		"password_notice": "Must be 6 ~ 20 alphanumeric",
		"password_confirmation_notice": "Please re-enter your password",
		"name_notice": "please input your real name, can't change after register",
		"user_total_bet": "Total bets",
		"enter_error_feedback": "Wrong enter.",
		"user_data": "General Information",
		"profile": "Member Center",
		"user_account": "Player ID",
		"user_id": "Player ID",
		"target_account": "The counterpart",
		"phone_number": "Contact Number",
		"required": "Empty or format error.",
		"captcha": "Validation code",
		"captcha_required": "Empty Validation code, please enter.",
		"agree": "I am at least 18 years of age and I have read, accept and agree to all the Terms and Conditions",
		"terms": "Terms and Conditions",
		"submit": "Submit",
		"verify_btn": "Send validation",
		"account_notice": "Must be 6 ~ {max} alphanumeric",
		"name_notice": "please input your real name, can't change after register",
		"id_group612": "Password must be 6~12 alphanumeric.",
		"id_group620": "English and number, at least 6-20 (notice the big or small letter)",
		"rememberme": "Remember me",
		"tip_idpw_not_same": "Must Different From Player Id.",
		"tip_phone_captcha_first": "Please send cellphone validation code first.",
		"tip_plz_agree": "Please agree the related rules and service conditions",
		"tip_id_ok": "The account is checked successfully",
		"tip_phone_captcha_send": "Mobile authentication code has been sent to you phone number",
		"ButSendMobileCode_second_reset": "Send again in a sec",
		"ButSendMobileCode_captcha_send": "Send validation",
		"join_description": "",
		"login_description": "",
		"forgot_description": "",
		"whats_app": "WhatsApp",
		"registered_mobile": "Please enter your registered mobile number",
		"line_login": "Line Login"
	},

	"dialogRule_title": "Terms and Conditions",
	"dialogRule_list": [
		{
			"title": "Terms and Conditions",
			"cont1": "After understanding and register being the member, you can use all services we provide."
		},
		{
			"title": "Follow member rules and law",
			"cont1": "When members using the services means you are not only agree servce conditions, but also agree the conditions from our company (including all the game rules, ball games betting rules, notices and precautions...), and law related limitation, all products from company also Customer Service, software system, internet strcture, ect related business stuff are all vertify by Philippines amusement and betting institution."
		},
		{
			"title": "Service Introduction",
			"cont1": `The company means to create a "safty", "immediate convininent", "fair and just", "professional running" nice amusement service platform. Emphasizing players an unlimitited space, join in a just game anytime 24 hours, can also enjoy playing with players from around the world. "players are first " is our attitude to follow, innovation and knwoing players need is our managing concept we pround of, hoping to create a new amusement value and being universal, become a leader on amusement website.`,
			"cont2": "The company provides platform of payment online, the points you buy can enjoy all the service on company platform. If you buy points by unjust way, company can stop your membership and rights on the platform services anytime."
		},
		{
			"title": "Real log in duty",
			"cont1": "Base on company services, you agree items that appear when you register for the membership, log in with real, accurate, and complete personal information. If your information changes, please change immediately to maintain the correct, real, and complete infromation.",
			"cont2": "If you log in with fake infromation or use other people's names that infringe other people's rights, you should stand the legal liability and agree if your infromation changes but you didn't change it immediately  that make the imformation not real, the company has the rights to stop your membership and all the company services."
		},
		{
			"title": "Stop service or change",
			"cont1": "• Member log in fake information.",
			"cont2": "• Use other people's name to apply our service.",
			"cont3": "• Violate game justice rule.",
			"cont4": "• When join the games, find system errors or use game weakness to effect results on purpose.",
			"cont5": "• Violate this service conditions.",
			"cont6": "• The company does not allow unjust betting way, double betting, ways that without risks.If find out the players that play with those ways above, the company has the right to stop the member rights. If there is any dispute, the company has the final right to decide.",
			"cont7": "• No matter what situation that stop or change service or stop mamber accounts which might cause damage, inconvinient, trouble, this service won't have any duty to any members or the third person."
		},
		{
			"title": "Service stop or intrrupt",
			"cont1": "This company can stop or intrrupt this service or all service by any following  situations, and no any compensation responsibity for the players.",
			"cont2": "• When the related system are moving, changing, or maintaining.",
			"cont3": "• Can't blame the company for the service stop or intrrupt.",
			"cont4": "• For the reasons that can't control to cause the servie stop or intrrupt.",
			"cont5": "If the company movig, change, or maintain the related system that have to stop or intrrupt parts of or the all service, the company will send the emails or notice on the website before it happens.",
			"cont6": "The company does not allow unjust betting way, double betting, ways that without risks.If find out the players that play with those ways above, the company has the right to stop the member rights. If there is any dispute, the company has the final right to decide.",
			"cont7": "About the service stop or intrruption that might cause your inconvinient, information lose or damages on economic or time. You should apply some protections to protect your rights."
		},
		{
			"title": "Keeping duty.",
			"cont1": "Members have the duty to keep the accounts and passwords on the website, and take the responsibility to all the actions after logging in the set of the account and password.",
			"cont2": "To protect the member rights, don't leak, give, lend, or transfer for  other people to use."
		},
		{
			"title": "Agree all the sports games and games rules.",
			"cont1": "To avoid users that have disputes on the betting games, there are detailed conditions for the all rules on any games, please read the service and the rules clearly.",
			"cont2": "When the members start using this service, means you have already accpeted all the rules."
		},
		{
			"title": "Member rules of adding and changing.",
			"cont1": "If the service conditions are adding or changing, you agree that it valids when it notices on the website, the website won't notice individually.",
			"cont2": "If you use the service after notice means you agree this condition that is changed."
		},
		{
			"title": "Member Policy",
			"cont1": "We devoted to protect the member privacy and provide the safest game platform, the information we collect on the website will provide you the greatest service, we won't sale or rent out your information for the third person.",
			"cont2": "The information that customers provide are all encrypted by SSL128 and save in the safe, non-public system. Partners that have the opprotunuty to deal with the member personal information have to follow our provicy rules."
		},
		{
			"title": "Terms and Conditions",
			"cont1": "Most of the region or country haven't have the clear  legality on onine betting, even some regions or countries say that the online betting are illegal. We have no desire to invite any of that countries to use our service.",
			"cont2": "Users have to make sure that wheather it's legal for online betting in your region or country, and take the all responsibility. The members should follow the local law, if there is violation, the company has no duty."
		}
	],
}
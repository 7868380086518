import { setToken } from '~/utils/token'
import { required, requiredIf, minLength, maxLength, alphaNum, numeric, email, sameAs } from "vuelidate/lib/validators";

export const state = () => ({
    AreaCode: {}, //電話區碼
    AccountSign: [], //註冊欄位
    AccountWalletNum: 0, //會員主錢包
    AccountWallet: { 0: 0 }, //會員主錢包
    AccountWalletData: {}, //會員主錢包
    AccountWalletLoading: { '0': false }, //會員主錢包
    AccountPromote: {}, //會員推廣資料
    DistributionList: [], //分銷會員
})

export const getters = {
    AccountSign: state => {
        let data = []
        state.AccountSign.forEach((item, index) => {
            if (item.show == 1) data.push(item)
        })
        return data
    },
    AccountSignInfo: (state) => (field) => {
        return state.AccountSign.find(element => element.field === field)
    },
    AccountSignValidations: (state, getters, rootState, rootGetters) => {
        if (!state.AccountSign) return false

        let validate = {}
        state.AccountSign.forEach((item, index) => {
            if (item.show == 1) validate[item.field] = GetValidate(item, rootState.WebSetting)
        })

        if (Object.keys(rootState.WebSetting).indexOf('SIGN_CAPTCHAS') >= 0 && rootState.WebSetting.SIGN_CAPTCHAS == '1')
            validate['signcaptcha'] = { required }
        if (Object.keys(rootState.WebSetting).indexOf('SIGN_MOBILE_CAPTCHAS') >= 0 && rootState.WebSetting.SIGN_MOBILE_CAPTCHAS == '1')
            validate['usercaptcha'] = { required }

        return validate
    },
    AreaCode: state => {
        let data = [];
        for (var item in state.AreaCode) {
            data.push({value: state.AreaCode[item].id, text: `${state.AreaCode[item].name}(${state.AreaCode[item].code})`})
        }
        return data
    },
    AccountWallet: state => {
        return state.AccountWallet
    },
    AccountWalletLoading: state => {
        return state.AccountWalletLoading
    },
    AccountWalletList: (state, getters, rootState, rootGetters) => {
        let data = []
        rootGetters.Wallet(null).forEach((item, index) => {
            data.push({
                id: index + 1,
                name: item.text,
                wallet: item.value,
                status_name: null, //'store.normal' store.stop_using
                seamless: item.seamless
            })
        })
        return data
    },
    AccountWalletOne: (state) => (field) => {
        if (!field)
            return 0
        else if (Object.keys(state.AccountWalletLoading).indexOf(field.toString()) >= 0 && state.AccountWalletLoading[field])
            return 'loading'
        else if (Object.keys(state.AccountWalletData).indexOf(field.toString()) >= 0 && state.AccountWalletData[field].maintain == '1')
            return 'maintain'
        else if (Object.keys(state.AccountWallet).indexOf(field.toString()) >= 0)
            return state.AccountWallet[field]
        else
            return 0
    },
    DistributionList: state => {
        let data = [];
        state.DistributionList.forEach(element => {
            data.push({ value: element.id, text: element.account })
        });
        return data
    },
}

export const mutations = {
    _setAreaCode: (state, data) => {
        state.AreaCode = data;
    },
    _setAccountSign: (state, data) => {
        state.AccountSign = data;
    },
    setAccountWallet: (state, data) => {
        state.AccountWallet[data.code] = data.wallet
        state.AccountWalletData[data.code] = data.data
        state.AccountWalletLoading[data.code] = false
        state.AccountWalletNum ++
    },
    setAccountWalletLoading: (state, data) => {
        state.AccountWalletLoading[data.code] = data.status
        state.AccountWalletNum ++
    },
    setAccountWalletData: (state, data) => {
        state.AccountWallet[data] = null
        state.AccountWalletData[data] = { status: '1', maintain : '0'}
        state.AccountWalletLoading[data] = false
        state.AccountWalletNum ++
    },
    setAccountPromote: (state, data) => {
        state.AccountPromote = data
    },
    setDistributionList: (state, data) => {
        state.DistributionList = data
    }
}

export const actions = {
    async _getAreaCode({ rootState, commit, dispatch }) {
        if (!rootState.NeedGetData.AreaCode) return
        const response = await this.$axios.post('/api/GetAreaCode');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setAreaCode', response.data);
                commit('_setNeedGetData', { name: 'AreaCode', type: false }, { root: true });
            }
            return res
        })
    },
    async _getAccountSign({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.AccountSign) return
        const response = await this.$axios.post('/api/GetAccountSign');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setAccountSign', response.data);
                commit('_setNeedGetData', { name: 'AccountSign', type: false }, { root: true });
            }
            return res
        })
    },
    async _setUpAgentCode({ commit }, data) {
        const response = await this.$axios.post('/api/SetUpAgentCode', data);
        if (response.status == 'ok') {
            this.$cookies.remove('UpAgentCode')
            this.$cookies.set('UpAgentCode', data.code, { path: '/', maxAge: 60 * 60 })
            commit('_setUpAgentCode', data.code, { root: true });
            return true;
        }
        else {
            commit('_setErrorMsg', response.msg, { root: true });
            return false;
        }
    },
    async _getUpAgentCode({ commit }, data) {
        const response = await this.$axios.post('/api/SetUpAgentCode', data);
        if (response.status == 'ok') {
            this.$cookies.remove('UpAgentCode')
            this.$cookies.set('UpAgentCode', data.code, { path: '/', maxAge: 60 * 60 })
            commit('_setUpAgentCode', data.code, { root: true });
            return true;
        }
        else {
            commit('_setErrorMsg', response.msg, { root: true });
            return false;
        }
    },
    async CheckImportAccount({ dispatch }, data) {
        const response = await this.$axios.post('/api/CheckImportAccount', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return response.data;
        })
    },
    async CheckAccount({ dispatch }, data) {
        const response = await this.$axios.post('/api/CheckAccount', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
    async SendMobileCode({ dispatch }, data) {
        const response = await this.$axios.post('/api/SendMobileCode', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (response.data) {
                return response.data.rew || res
            } else {
                return res
            }
        })
    },
    async AccountFrontInsert({ rootState, dispatch, commit }, data) {
        data.host = rootState.Host
        const response = await this.$axios.post('/api/Account/FrontInsert', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (response.status == 'ok') {
                this.$cookies.remove('UpAgentCode')
                commit('_setUpAgentCode', null, { root: true });
                this.$auth.setUserToken(response.data).then(() => dispatch('GetAccountData'))
            }

            return res
        })
    },
    async GetAccountWallet({ state, dispatch, commit }, code) {
        if (state.AccountWalletLoading[code]) return

        commit('setAccountWalletLoading', { status: true, code });
        const response = await this.$axios.post('/api/Account/Wallet/' + code);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res)
                commit('setAccountWallet', { code: code, wallet: response.data.wallet, data: { status: response.data.status, maintain: response.data.maintain }});

            return res
        }).finally((res) => {
          commit('setAccountWalletLoading', { status: false, code });
        });
    },
    async GetAccountGameWallet({ state, dispatch, commit }, code) {
        if (state.AccountGameWalletLoading[code]) return
        commit('setAccountGameWalletLoading', { status: true, code });
        const response = await this.$axios.post('/api/Account/Wallet/' + code);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res)
                commit('setAccountGameWallet', { code: code, wallet: response.data.wallet });

            commit('setAccountGameWalletLoading', { status: false, code });
            return res
        })
    },
    async GetAccountData({ state, dispatch, commit }) {
        return dispatch('GetAccountWallet', 0).then(() => {
            commit('_setNeedGetData', { name: 'AccountWallet', type: false }, { root: true });
            return;
        })
    },
    async GetAccountPromote({ state, dispatch, commit }) {
        const response = await this.$axios.post('/api/Account/Promote');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res)
                commit('setAccountPromote', response.data);
            return res
        })
    },
    async ChangePassword({ dispatch }, data) {
        const response = await this.$axios.post('/api/Account/ChangePassword', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
    async NewSafePassword({ dispatch }, data) {
        const response = await this.$axios.post('/api/Account/NewSafePassword', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
    async ChangeSafePassword({ dispatch }, data) {
        const response = await this.$axios.post('/api/Account/ChangeSafePassword', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
    async ForgotPassword({ dispatch }, data) {
        const response = await this.$axios.post('/api/ForgotPassword', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (response.data) {
                return response.data.rew || res
            } else {
                return res
            }
        })
    },
    async UserLogin({ dispatch }, data) {
        const response = await this.$axios.post('/api/login', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res)
                this.$auth.setUserToken(response.data.access_token).then(() => dispatch('GetAccountData'))

            return res
        })
    },
    async getDistributionList({ dispatch, commit }, data) {
        const response = await this.$axios.post('/api/Account/Distribution');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res)
                commit('setDistributionList', response.data);
            return res
        })
    },
    async ChangePointModel({ dispatch }, data) {
        const response = await this.$axios.post('/api/Account/ChangePointModel', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
}

function GetValidate(data, webSetting) {
    if (!data.field) return {}

    let Validate      = {}
    let AccountLen    = parseInt(Object.keys(webSetting).indexOf('ACCOUNT_LEN') >= 0 ? webSetting.ACCOUNT_LEN : 10);
    Validate.required = requiredIf(function () { return data.required > 0 });

    if (data.rule_front && Object.keys(data.rule_front).length > 0) {
        Object.keys(data.rule_front).forEach((item, index) => {
            switch (item) {
                case 'maxLength':
                    if (data.rule_front[item] == '{AccountLen}')
                        Validate.maxLength = maxLength(AccountLen);
                    else
                        Validate.maxLength = maxLength(data.rule_front[item]);
                    break;
                case 'minLength':
                    Validate.minLength = minLength(data.rule_front[item]);
                    break;
                case 'alphaNum':
                    Validate.alphaNum = alphaNum;
                    break;
                case 'numeric':
                    Validate.numeric = numeric;
                    break;
                case 'email':
                    Validate.email = email;
                    break;
                case 'sameAs':
                    Validate.sameAs = sameAs(data.rule_front[item]);
                    break;
            }
        });
    }

    return Validate
}